import Tax from '../Interfaces/Tax';

export default class TaxDescriptionService {
  public generateTaxDescription(taxesToCheck: Tax[], taxes: Tax[]): string {
    if (this.hasAllTaxes(taxesToCheck, taxes)) {
      return 'All Taxes';
    }

    if (this.hasSomeTaxes(taxesToCheck, taxes)) {
      return `${taxesToCheck.length} Taxes`;
    }

    if (this.hasOneTax(taxesToCheck)) {
      return `${taxesToCheck[0].name}`;
    }

    if (this.hasNoTaxes(taxesToCheck)) {
      return 'No Taxes';
    }

    return '';
  }

  private hasAllTaxes(taxesToCheck: Tax[], allTaxes: Tax[]) {
    return (
      taxesToCheck.length === allTaxes.length &&
      taxesToCheck.length > 1 &&
      allTaxes.length > 1
    );
  }

  private hasSomeTaxes(taxesToCheck: Tax[], allTaxes: Tax[]) {
    return taxesToCheck.length > 1 && taxesToCheck.length < allTaxes.length;
  }

  private hasOneTax(taxesToCheck: Tax[]) {
    return taxesToCheck.length === 1;
  }

  private hasNoTaxes(taxesToCheck: Tax[]) {
    return taxesToCheck.length === 0;
  }
}
