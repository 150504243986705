
import { Options, Vue } from 'vue-class-component';
import ItemDetail from './ItemDetail.vue';
import VariantStock from '@/components/VariantStock.vue';
import ItemService from '../services/ItemService';
import VariantLocationService from '../services/VariantLocationService';
import LocationDescriptionService from '../services/LocationDescriptionService';
import LocationService from '../services/LocationService';
import RoleService from '../services/RoleService';
import LocalStorageService from '../services/LocalStorageService';
import TaxService from '../services/TaxService';
import TaxDescriptionService from '../services/TaxDecriptionService';
import ConfirmationService from '../services/ConfirmationService';
import { createLogger, mapActions, mapGetters } from 'vuex';
import Variant from '@/Interfaces/Variant';
import Location from '@/Interfaces/Location';

import router from '@/router';
import { cloneDeep, isEqual } from 'lodash';
import { toRaw } from 'vue';
import { ItemType } from '@/enums/ItemType';
import Tax from '@/Interfaces/Tax';

@Options({
  components: {
    ItemDetail,
    VariantStock,
  },
  providers: [ConfirmationService],
  methods: {
    ...mapActions(['getLocationList', 'getTaxList', 'getTaxes']),
    async productTypeChanged(itemType: any) {
      let newSelectedTax = [];
      if (itemType == ItemType.Good) {
        this.selectedTaxes = [];
        newSelectedTax = this.allTaxes.filter((tax: Tax) => {
          return tax.applyToAllGoods === true;
        });
      } else if (itemType == ItemType.Service) {
        this.selectedTaxes = [];
        newSelectedTax = this.allTaxes.filter((tax: Tax) => {
          return tax.applyToAllServices === true;
        });
      }

      if (newSelectedTax.length > 0) {
        newSelectedTax.forEach((tax: Tax) => {
          if (
            !this.selectedTaxes.some(
              (selectedTax: any) => selectedTax.id === tax.id,
            )
          ) {
            this.selectedTaxes.push(tax);
          }
        });
      }
      await this.getTaxes(this.selectedLocationId).then(() => {
        this.taxDescription = this.updateTaxDescription(this.selectedTaxes);
      });
    },
  },
  computed: {
    itemType() {
      return this.item.itemType;
    },
    locationId() {
      return this.item.locations && this.item.locations.length > 0
        ? this.item.locations[0].id
        : null;
    },
    ...mapGetters(['allTaxeList', 'allTax', 'taxFilter']),
  },
  props: ['id'],
  watch: {
    item: {
      handler(newValue) {
        if (this.countLoading > 1 && newValue.itemType == ItemType.Good) {
          this.setDiscardChangesModal = true;
        } else if (
          this.countLoading > 2 &&
          newValue.itemType == ItemType.Service
        ) {
          this.setDiscardChangesModal = true;
        } else if (
          this.countLoading > 3 &&
          newValue.itemType == ItemType.Bundle
        ) {
          this.setDiscardChangesModal = true;
        }
        this.countLoading++;
      },
      deep: true,
    },
    async locationId(newValue) {
      if (!this.isEditMode) {
        this.selectedLocationId = newValue;
        this.isSelectedLocId = newValue;
        await this.sharedMounted(this.selectedLocationId);
      }
    },
    itemType(newValue) {
      if (!this.isEditMode) {
        this.productTypeChanged(newValue);
      }
    },
  },
})
export default class ItemDetailContainer extends Vue {
  alertTitle = '';
  alertMessage = '';
  showConfirm = false;

  countLoading = 0;
  activateNotification = false;
  typeNotification = 'success';
  titleNotification = '';
  messageNotification = '';

  itemsService = new ItemService();
  locationDescriptionService = new LocationDescriptionService();
  locationService = new LocationService();
  variantLocationService = new VariantLocationService();
  localStorageService = new LocalStorageService();
  roleService = new RoleService(new LocalStorageService());
  taxService = new TaxService();
  taxDescriptionService = new TaxDescriptionService();
  confirmationService = new ConfirmationService();

  // Data Elements
  locations: any = [];
  isEditMode = false;
  readyToLoad = false;
  loadingContent = false;
  showStockComponent = false;
  selectedLocations: any[] = [];
  selectedLocationsVariant: any = {};
  selectedVariantLocations: any = [];
  locationDescription = '';
  allLocations: any[] = [];
  deletedVariants: any[] = [];
  selectedTaxes: any[] = [];
  allTaxes: any[] = [];
  taxDescription = '';
  checkUPC = false;
  isSelectedLocId: any = [];
  isSuperadminLoc = false;
  page: any = 1;
  pageSize: any = 3;
  selectedLocationId: any;
  selectedLocationIdRef: any;
  item: any = {
    id: '',
    name: '',
    description: '',
    manufacturer: '',
    itemType: ItemType.Good,
    variants: [
      {
        id: '',
        name: '',
        sku: '',
        price: 0,
        variantLocations: [],
        isDeleted: false,
        upc: '',
        isActive: false,
        quantity: 1,
        messageValidations: {
          upc: '',
        },
        wholesalePrice: 0,
      },
    ],
    bundles: [],
    locations: [],
    hasImage: false,
    imageSrc: undefined,
    bundleSKU: '',
    price: '$0',
  };
  itemRef;

  variant: any = {
    name: '',
    sku: '',
    price: 0,
    upc: '',
    quantity: 1,
    variantLocations: [
      {
        location: {
          id: '',
          name: '',
          isActive: true,
          email: '',
          filterByInventoryTracking: false,
        },
        currentStock: 0,
        isInventoryTrackingEnabled: false,
        isActive: false,
        isLowStock: false,
        stockThreshold: 0,
      },
    ],
    isActive: false,
  };

  variantSnap: any[] = [];

  initialVariantValues: any[] = [
    {
      name: '',
      sku: '',
      price: 0,
      quantity: 1,
      variantLocations: [
        {
          location: {
            id: '',
            name: '',
            isActive: true,
            email: '',
            filterByInventoryTracking: false,
          },
          currentStock: 0,
          isInventoryTrackingEnabled: false,
          isActive: false,
          isLowStock: false,
          stockThreshold: 0,
        },
      ],
      upc: '',
      isActive: false,
    },
  ];

  selectedVariantSnapshot: any = {
    name: '',
    sku: '',
    price: 0,
    quantity: 1,
    variantLocations: [
      {
        location: {
          id: '',
          name: '',
          isActive: true,
          email: '',
          filterByInventoryTracking: false,
        },
        currentStock: 0,
        isInventoryTrackingEnabled: false,
        isActive: false,
        isLowStock: false,
        stockThreshold: 0,
      },
    ],
    upc: '',
    isActive: false,
  };

  setDiscardChangesModal = false;
  showDiscardChangesModal = false;

  // Lifecycle
  created() {
    this.roleService.getRole();
  }

  async showNotifications(data: {
    activate: boolean;
    type: 'success' | 'error';
    title: string;
    message: string;
  }) {
    this.activateNotification = data.activate;
    this.typeNotification = data.type;
    this.titleNotification = data.title;
    this.messageNotification = data.message;
    await new Promise((resolve) => {
      setTimeout(resolve, 5000);
    }).then(() => {
      this.activateNotification = false;
    });
  }

  async sharedMounted(locId?: string) {
    const itemId: any = this.$route.params.id;
    this.setIsEditMode(itemId);
    if (locId) {
      this.selectedLocationId = locId;
      this.isSelectedLocId = locId;
    } else {
      this.isSelectedLocId =
        this.$route.query && this.$route.query.locationId
          ? this.$route.query.locationId
          : '';
    }
    const locationOfSuperadmin = this.localStorageService.getItem('locations');

    await this.$store.dispatch('getLocationList');
    this.locations = this.$store.getters.locations;
    this.allLocations = this.$store.getters.locations.map((location: any) => ({
      topCheckBox: true,
      id: location.id,
      name: location.name,
      isActive: true,
      email: location.email,
      filterByInventoryTracking: location.filterByInventoryTracking,
    }));

    if (locationOfSuperadmin && this.roleService.isSuperadmin) {
      const allTaxesPromise =
        this.isSelectedLocId !== ''
          ? await this.taxService.getTaxesByLocation(this.isSelectedLocId)
          : await this.taxService.getTaxesByLocation(
              locationOfSuperadmin.slice(2, -2),
            );
      this.allTaxes = allTaxesPromise.data.taxes.map((tax: any) => ({
        id: tax.id,
        name: tax.name,
      }));
      if (
        locationOfSuperadmin.slice(2, -2) === this.isSelectedLocId ||
        !this.isEditMode
      ) {
        this.allLocations = [
          ...this.allLocations.filter(
            (l: any) => l.id === locationOfSuperadmin.slice(2, -2),
          ),
        ];
      }
      if (
        locationOfSuperadmin.slice(2, -2) == this.isSelectedLocId &&
        this.isEditMode
      ) {
        this.isSuperadminLoc = true;
      } else {
        this.isSuperadminLoc = false;
      }
    } else {
      if (locId) {
        this.selectedLocationId = locId;
        this.isSelectedLocId = locId;
      } else {
        this.isSelectedLocId =
          this.$route.query && this.$route.query.locationId
            ? this.$route.query.locationId
            : this.localStorageService.getItem('primary_location_id');
      }
    }

    if (this.isEditMode) {
      const itemPromise = await this.itemsService.getItemById(
        itemId,
        this.$route.query.locationId.toString(),
      );
      this.item = itemPromise.data;
      this.item.variants = this.item.variants.map(this.mapVariants);
      this.item.bundleSKU =
        this.item.bundles && this.item.bundles[0]
          ? this.item.bundles[0].bundleSKU
          : '';
      for (let i = 0; i < this.item.variants.length; i++) {
        this.item.variants[i].isActive = false;
      }
      for (let i = 0; i < this.item.bundles?.length; i++) {
        const new_item = {
          name: '',
          manufacturer: '',
          itemId: '',
          variantId: '',
          variantName: '',
          variantPrice: '',
          variantSku: '',
          variantUpc: '',
          bundleSKU: '',
          bundleItemId: '',
          item: {},
          variant: {},
        };
        new_item.name = this.item.bundles[i].item.name;
        new_item.manufacturer = this.item.bundles[i].item.manufacturer;
        new_item.itemId = this.item.bundles[i].item.id;
        new_item.variantId = this.item.bundles[i].variant.id;
        new_item.variantName = this.item.bundles[i].variant.name;
        new_item.variantPrice = this.item.bundles[i].variant.price;
        new_item.variantSku = this.item.bundles[i].variant.sku;
        new_item.variantUpc = this.item.bundles[i].variant.upc;
        new_item.bundleSKU = this.item.bundles[i].bundleSKU;
        new_item.item = this.item.bundles[i].item;
        new_item.variant = this.item.bundles[i].variant;
        new_item.bundleItemId = this.item.id;
        this.item.bundles[i] = new_item;
      }

      if (this.item.itemType === ItemType.Bundle) {
        this.selectedLocations = this.item.locations.map((location: any) => ({
          topCheckBox: true,
          id: location.id,
          name: location.name,
          isActive: true,
          email: location.email,
          filterByInventoryTracking: location.filterByInventoryTracking,
        }));
      } else {
        this.selectedLocations = this.item.locations.map((location: any) => ({
          topCheckBox: true,
          id: location.id,
          name: location.name,
          isActive: true,
          email: location.email,
          filterByInventoryTracking: location.filterByInventoryTracking,
        }));
      }

      for (let i = 0; i < this.item.variants.length; i++) {
        for (
          let j = 0;
          j < this.item.variants[i].variantLocations.length;
          j++
        ) {
          for (let a = 0; a < this.item.locations.length; a++) {
            const location = this.item.locations[a];
            if (!this.selectedLocationsVariant[this.item.variants[i].id]) {
              this.selectedLocationsVariant[this.item.variants[i].id] = [];
            }
            if (
              location &&
              this.item.variants[i].variantLocations[j].location &&
              location.id ==
                this.item.variants[i].variantLocations[j].location.id
            ) {
              this.selectedLocationsVariant[this.item.variants[i].id].push({
                locationid:
                  this.item.variants[i].variantLocations[j].location.id,
                topCheckBox: true,
                id: location.id,
                name: location.name,
                isActive: true,
                email: location.email,
                filterByInventoryTracking: location.filterByInventoryTracking,
                isInventoryTrackingEnabled:
                  this.item.variants[i].variantLocations[j]
                    .isInventoryTrackingEnabled,
                isLowStock:
                  this.item.variants[i].variantLocations[j].isLowStock,
              });
            }
          }
        }
      }

      const getTaxesPromise = await this.itemsService.getTaxesForItem(
        itemId,
        this.$route.query.locationId.toString(),
      );
      this.selectedTaxes = getTaxesPromise.data.taxes.map((tax: any) => ({
        id: tax.id,
        name: tax.name,
        applyToAllGoods: tax.applyToAllGoods,
        applyToSpecificItems: tax.applyToSpecificItems,
        applyToAllServices: tax.applyToAllServices,
        value: tax.value,
        isDeleted: tax.isDeleted,
      }));

      if (this.item.itemType === ItemType.Service) {
        this.item.price = this.item.variants[0].price;
        this.item.serviceCode = this.item.variants[0].upc;
      }

      if (this.item.itemType === ItemType.Bundle) {
        this.item.bundleSKU =
          this.item.bundles && this.item.bundles[0]
            ? this.item.bundles[0].bundleSKU
            : '';
        this.selectedLocationId = this.$route.query.locationId.toString();
      } else {
        if (locId) {
          this.selectedLocationId = locId;
          this.isSelectedLocId = locId;
        } else {
          this.selectedLocationId = this.$route.query.locationId.toString();
        }
      }
    } else {
      this.selectedLocations =
        this.allLocations.length === 1 ? this.allLocations : [];
      this.selectedTaxes = [];
      this.selectedLocations.forEach((location) => {
        this.findOrInitializeVariantLocation(this.item.variants[0], location);
      });
      if (locId) {
        this.selectedLocationId = locId;
        this.isSelectedLocId = locId;
      } else {
        this.selectedLocationId = this.localStorageService.getItem(
          'primary_location_id',
        );
      }
    }
    this.itemRef = { ...cloneDeep(this.item) };
    this.selectedLocationIdRef = this.selectedLocationId;

    this.locationDescription = this.updateLocationsDescription(
      this.selectedLocations,
    );
    await this.getTaxes(this.selectedLocationId).then(() => {
      this.taxDescription = this.updateTaxDescription(this.selectedTaxes);
    });
    this.readyToLoad = true;
    this.saveInitialVariant();

    let newSelectedTax = [];

    if (this.item.itemType == ItemType.Good) {
      newSelectedTax = this.allTaxes.filter((tax: Tax) => {
        return tax.applyToAllGoods === true;
      });
    } else if (this.item.itemType == ItemType.Service) {
      newSelectedTax = this.allTaxes.filter((tax: Tax) => {
        return tax.applyToAllServices === true;
      });
    }

    if (!this.isEditMode) {
      if (newSelectedTax.length > 0) {
        newSelectedTax.forEach((tax: Tax) => {
          if (
            !this.selectedTaxes.some(
              (selectedTax: any) => selectedTax.id === tax.id,
            )
          ) {
            this.selectedTaxes.push(tax);
          }
        });
      }

      await this.getTaxes(this.selectedLocationId).then(() => {
        this.taxDescription = this.updateTaxDescription(this.selectedTaxes);
      });
    }
  }

  async mounted() {
    await this.sharedMounted();
  }

  async getTaxes(locationId: string) {
    const allTaxesPromise = await this.taxService.getTaxesByLocation(
      locationId,
    );
    this.allTaxes = allTaxesPromise.data.taxes.map((tax: any) => ({
      id: tax.id,
      name: tax.name,
      applyToAllGoods: tax.applyToAllGoods,
      applyToSpecificItems: tax.applyToSpecificItems,
      applyToAllServices: tax.applyToAllServices,
      value: tax.value,
      isDeleted: tax.isDeleted,
    }));
  }

  handleCancel(value: boolean): void {
    this.confirmationService.setFlagValue(false);
    if (this.setDiscardChangesModal || value) {
      this.showDiscardChangesModal = true;
    } else {
      this.navigateAway();
    }
  }
  // Props methods
  addVariant(): void {
    this.setDiscardChangesModal = true;
    this.itemsService.addVariant(this.item);
    this.selectedLocations.forEach((location) => {
      this.findOrInitializeVariantLocation(
        this.item.variants[this.item.variants.length - 1],
        location,
      );
    });
  }

  async addLocationsToItem(selectedLocations: Location[]) {
    this.loadingContent = true;
    if (selectedLocations[0]) {
      this.setDiscardChangesModal = !this.selectedLocationId.includes(
        selectedLocations[0]?.id,
      );
      await this.getTaxes(selectedLocations[0].id);
    }
    this.loadingContent = false;
    this.selectedLocations = selectedLocations;
    this.locationDescription =
      this.updateLocationsDescription(selectedLocations);

    this.updateVariantLocationIsActiveStatus(selectedLocations);
    this.selectedLocationsVariant = {};
    for (let i = 0; i < this.item.variants.length; i++) {
      for (let j = 0; j < this.item.variants[i].variantLocations.length; j++) {
        if (this.item.variants[i].variantLocations[j].isActive) {
          this.selectedLocationsVariant[this.item.variants[i].id] =
            this.item.locations.map((location: any) => ({
              locationid: this.item.variants[i].variantLocations[j].location.id,
              topCheckBox: true,
              id: location.id,
              name: location.name,
              isActive: true,
              email: location.email,
              filterByInventoryTracking: location.filterByInventoryTracking,
              isInventoryTrackingEnabled:
                this.item.variants[i].variantLocations[j]
                  .isInventoryTrackingEnabled,
              isLowStock: this.item.variants[i].variantLocations[j].isLowStock,
            }));
        }
      }
    }
    this.$forceUpdate();
  }
  async updateVariantLocationIsActiveStatus(selectedLocations: Location[]) {
    // IVI-213: Changed multilocation functionality to the single location
    const locationids = selectedLocations.map((location: any) => location.id);
    this.locations.forEach((location: any) => {
      if (locationids.includes(location.id)) {
        this.item.variants.forEach((variant: any, index: any) => {
          let newVariantLocation = variant.variantLocations.find(
            (variantLocation: any) => {
              return (
                variantLocation &&
                variantLocation.location &&
                location &&
                variantLocation.location.id === location.id
              );
            },
          );

          if (newVariantLocation === undefined) {
            variant.variantLocations = [];
            newVariantLocation = {
              location: location,
              isInventoryTrackingEnabled: false,
              currentStock: 0,
              isActive: true,
              untouched: true,
              isLowStock: false,
              stockThreshold: 0,
            };
            variant.variantLocations.push(newVariantLocation);
          }
        });
      }
    });
  }

  /**
   * Adds message validations attribute to manage the messages according to each UPC variant.
   */
  mapVariants(item: any): any {
    return {
      ...item,
      messageValidations: {
        upc: '',
      },
    };
  }

  addTaxesToItem(selectedTaxes: any[]): void {
    this.setDiscardChangesModal =
      selectedTaxes.length > this.selectedTaxes.length;
    this.selectedTaxes = selectedTaxes;
    this.taxDescription = this.updateTaxDescription(selectedTaxes);
  }

  findOrInitializeVariantLocation(variant: any, location: any): any {
    let variantLocation = variant.variantLocations.find(
      (variantLocation: any) => {
        return (
          variantLocation &&
          variantLocation.location &&
          location &&
          variantLocation.location.id === location.id
        );
      },
    );
    if (variantLocation === undefined) {
      variantLocation = {
        location: location,
        isInventoryTrackingEnabled: false,
        currentStock: 0,
        isActive: true,
        untouched: true,
        isLowStock: false,
        stockThreshold: 0,
      };
      variant.variantLocations.push(variantLocation);
    }
    return variantLocation;
  }

  findVariantLocation(variant: any, location: any): any | undefined {
    const variantLocation = variant.variantLocations.find(
      (variantLocation: any) => {
        return (
          variantLocation &&
          variantLocation.location &&
          location &&
          variantLocation.location.id === location.id
        );
      },
    );
    return variantLocation;
  }

  updateVariantLocations(
    variant: any,
    variantLocations: any[],
    selectedStockActions: any,
    deltaStockValues: any,
    variantLocationSnapshots: any[],
    stockActions: any,
  ): void {
    variantLocations.forEach((variantLocation, index) => {
      if (
        this.stockActionIsSelected(
          variantLocation,
          selectedStockActions,
          'addStock',
        )
      ) {
        this.incrementStock(
          variantLocation,
          deltaStockValues,
          variantLocationSnapshots[index],
        );
      } else if (
        this.stockActionIsSelected(
          variantLocation,
          selectedStockActions,
          'removeStock',
        )
      ) {
        this.decrementStock(
          variantLocation,
          deltaStockValues,
          variantLocationSnapshots[index],
        );
      } else if (
        this.stockActionIsSelected(
          variantLocation,
          selectedStockActions,
          'recountStock',
        )
      ) {
        this.recountStock(variantLocation, deltaStockValues);
      }
      if (variantLocation && variantLocation.location)
        variantLocation.mostRecentStockAction =
          stockActions[variantLocation.location.id];
    });
    variant.variantLocations = variantLocations;
  }

  stockActionIsSelected(
    variantLocation: any,
    selectedStockActions: any,
    selectedStockAction: string,
  ): boolean {
    return (
      selectedStockActions &&
      variantLocation &&
      variantLocation.location &&
      selectedStockActions[variantLocation.location.id] === selectedStockAction
    );
  }

  incrementStock(variantLocation: any, deltaStockValues: any, snapshot: any) {
    if (variantLocation && variantLocation.location)
      variantLocation.currentStock =
        snapshot.currentStock +
        (parseInt(deltaStockValues[variantLocation.location.id]) || 0);
  }

  decrementStock(variantLocation: any, deltaStockValues: any, snapshot: any) {
    if (variantLocation && variantLocation.location)
      variantLocation.currentStock =
        snapshot.currentStock -
        (parseInt(deltaStockValues[variantLocation.location.id]) || 0);
  }

  recountStock(variantLocation: any, deltaStockValues: any) {
    if (variantLocation && variantLocation.location)
      variantLocation.currentStock =
        parseInt(deltaStockValues[variantLocation.location.id]) || 0;
  }

  handleVariantRemove(variant: any) {
    this.itemsService.handleVariantRemove(
      this.item,
      variant,
      this.deletedVariants,
    );
  }

  async handleSubmit(photo: File): Promise<void> {
    const word = this.isEditMode ? 'updated' : 'added';
    this.loadingContent = true;

    try {
      if (this.item.itemType === ItemType.Bundle) {
        this.item.price = 0;
        for (let i = 0; i < this.item.bundles?.length; i++) {
          this.item.bundles[i].bundleSKU = this.item.bundleSKU;
        }
        await this.itemsService.handleSubmit(
          this.item,
          this.selectedLocations,
          this.deletedVariants,
          this.isEditMode,
          photo,
          this.selectedTaxes,
          this.allTaxes,
          this.confirmationService,
          this,
          this.selectedLocationId,
        );
      } else {
        if (this.item.itemType === ItemType.Service) {
          this.item.variants[0].price = this.item.variants[0].price
            ? this.item.variants[0].price
            : 0;
          this.item.variants[0].name = this.item.name;
          this.item.variants[0].upc = this.item.variants[0].upc
            ? this.item.variants[0].upc
            : '';
          await this.itemsService.handleSubmit(
            this.item,
            this.selectedLocations,
            this.deletedVariants,
            this.isEditMode,
            photo,
            this.selectedTaxes,
            this.allTaxes,
            this.confirmationService,
            this,
            this.selectedLocationId,
          );
        } else {
          if (this.item.itemType === ItemType.Good) {
            const locationids = this.selectedLocations.map(
              (location: any) => location.id,
            );
            this.item.variants.forEach((variant: any, index: any) => {
              variant.variantLocations.forEach(
                (variantLocation: any, vlindex: any) => {
                  if (
                    variantLocation &&
                    variantLocation.location &&
                    !locationids.includes(variantLocation.location.id)
                  ) {
                    variantLocation.isActive = false;

                    if (
                      variantLocation.currentStock === '' ||
                      variantLocation.currentStock == null
                    ) {
                      variantLocation.currentStock = 0;
                    }
                    if (
                      variantLocation.isInventoryTrackingEnabled === '' ||
                      variantLocation.isInventoryTrackingEnabled == null
                    ) {
                      variantLocation.isInventoryTrackingEnabled = false;
                    }
                    if (
                      variantLocation.isLowStock === '' ||
                      variantLocation.isLowStock == null
                    ) {
                      variantLocation.isLowStock = false;
                    }
                    if (
                      variantLocation.stockThreshold === '' ||
                      variantLocation.stockThreshold == null
                    ) {
                      variantLocation.stockThreshold = 0;
                    }
                  } else {
                    variantLocation.isActive = true;
                  }
                },
              );
            });
            this.item.variants[0].name =
              this.item.variants[0].name === ''
                ? this.item.name
                : this.item.variants[0].name;
            await this.itemsService.handleSubmit(
              this.item,
              this.selectedLocations,
              this.deletedVariants,
              this.isEditMode,
              photo,
              this.selectedTaxes,
              this.allTaxes,
              this.confirmationService,
              this,
              this.selectedLocationId,
            );
          }
        }
      }

      this.alertTitle = 'success';
      this.alertMessage = `Product '${this.item.name}' ${word} successfully!`;
      this.showConfirm = true;

      setTimeout(() => {
        router.push('/items');
      }, 1000);
    } catch (error) {
      console.log(error);
      this.showNotifications({
        activate: true,
        message: `Couldn't save item`,
        title: 'Error',
        type: 'error',
      });
    }
    this.loadingContent = false;
  }

  async handleRemoveImage(item: any) {
    await this.itemsService.handleImageDelete(item);
  }

  handleShowVariantStock(variant: any) {
    this.setDiscardChangesModal = true;
  }

  private saveInitialVariant(): any {
    const variantSnap = JSON.stringify(this.item.variants);
    this.initialVariantValues = JSON.parse(variantSnap);
  }

  handleHideVariantStock() {
    this.showStockComponent = false;
    this.setDiscardChangesModal = false;
  }

  findVariantLocations(variant: any): any[] {
    return variant.variantLocations
      .filter((variantLocation: any) => {
        return variantLocation.isActive === true;
      })
      .map((variantLocation: any) => {
        return variantLocation && variantLocation.location;
      });
  }

  async handleRemoveItem(): Promise<void> {
    const locationId = this.$route.query.locationId.toString();
    this.loadingContent = true;
    this.confirmationService.setFlagValue(false);
    await this.itemsService.handleRemoveItem(this.item, this, locationId);
    this.loadingContent = false;
  }

  updateVariantLocationIsActive(
    event: boolean,
    variantLocations: any[],
    location: any,
  ) {
    this.variantLocationService.updateVariantLocationStatus(
      event,
      variantLocations,
      location,
    );
  }

  updateLocationTracking(
    event: boolean,
    variantLocations: any[],
    location: any,
  ) {
    this.variantLocationService.updateLocationTracking(
      event,
      variantLocations,
      location,
    );
  }

  // private methods
  private setIsEditMode(itemId: string): void {
    this.isEditMode = itemId !== undefined && itemId !== null;
  }

  private updateLocationsDescription(locations: any[]) {
    return this.locationDescriptionService.generateLocationDescription(
      locations,
      this.allLocations,
    );
  }

  private updateTaxDescription(taxes: any[]) {
    return this.taxDescriptionService.generateTaxDescription(
      taxes,
      this.allTaxes,
    );
  }

  changeItem() {
    const variantNames: any = [];
    const variantUpc: any = [];
    this.item.variants.map((variant: Variant) => {
      const newVariant = variant.name.trim().toLowerCase();
      const newUpcVariant = variant.upc;
      variantNames.push(newVariant);
      variantUpc.push(newUpcVariant);

      if (this.check_duplicate(variantNames, newVariant) > 1) {
        variant.isDuplicate = true;
      } else {
        variant.isDuplicate = false;
      }
      if (this.check_duplicate(variantUpc, newUpcVariant) > 1) {
        variant.isUpcDuplicate = true;
      } else {
        variant.isUpcDuplicate = false;
      }
    });
    this.checkItemChanges();
  }

  checkItemChanges() {
    const { variants, bundles, locations, ...rest } = this.item;
    const {
      variants: variantsRef,
      bundles: bundlesRef,
      locations: locationsRef,
      ...restRef
    } = this.itemRef;

    this.setDiscardChangesModal =
      !isEqual(rest, restRef) ||
      this.variantsChanged(toRaw(variants), variantsRef) ||
      this.variantsChanged(toRaw(bundles), bundlesRef);
  }

  variantsChanged(variants, variantsRef) {
    if (variants.length !== variantsRef.length) {
      return true;
    }
    for (const idx in variants) {
      if (!variantsRef[idx].name.includes(variants[idx].name)) {
        return true;
      }
      if (!variantsRef[idx].price === variants[idx].price) {
        return true;
      }
      if (!variantsRef[idx].wholesalePrice === variants[idx].wholesalePrice) {
        return true;
      }
      if (!variantsRef[idx].sku.includes(variants[idx].sku)) {
        return true;
      }
      if (!variantsRef[idx].upc.includes(variants[idx].upc)) {
        return true;
      }
    }
    return false;
  }

  check_duplicate(arr: any, val: any) {
    return arr.reduce((a: any, v: any) => (v === val ? a + 1 : a), 0);
  }

  importVariant(selectedVariant: any): void {
    this.itemsService.importVariant(this.item, selectedVariant);
    this.selectedLocations.forEach((location) => {
      this.findOrInitializeVariantLocation(
        this.item.variants[this.item.variants.length - 1],
        location,
      );
    });
  }

  navigateAway() {
    router.push('/items');
  }

  confirmationCancel() {
    this.showDiscardChangesModal = false;
  }
}
