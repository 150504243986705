import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Notification = _resolveComponent("Notification")
  const _component_Spinner = _resolveComponent("Spinner")
  const _component_ItemDetail = _resolveComponent("ItemDetail")
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Notification, {
      activate: _ctx.activateNotification,
      type: _ctx.typeNotification,
      title: _ctx.titleNotification,
      message: _ctx.messageNotification,
      onDeactivated: _cache[1] || (_cache[1] = $event => (_ctx.activateNotification = $event))
    }, null, 8, ["activate", "type", "title", "message"]),
    (!_ctx.readyToLoad)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.readyToLoad)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_ItemDetail, {
            loadingContent: _ctx.loadingContent,
            roleService: this.roleService,
            "is-edit-mode": _ctx.isEditMode,
            selected_location_id: _ctx.selectedLocationId,
            item: _ctx.item,
            confirmationService: _ctx.confirmationService,
            "selected-locations": _ctx.selectedLocations,
            "selected-locations-variant": _ctx.selectedLocationsVariant,
            "locations-description": _ctx.locationDescription,
            "all-locations": _ctx.allLocations,
            "all-taxes": _ctx.allTaxes,
            "selected-taxes": _ctx.selectedTaxes,
            "tax-description": _ctx.taxDescription,
            isSuperadminLoc: _ctx.isSuperadminLoc,
            "onAdd-variant": _ctx.addVariant,
            "onAdd-locations-to-item": _ctx.addLocationsToItem,
            "onHandle-variant-remove": _ctx.handleVariantRemove,
            "onHandle-submit": _ctx.handleSubmit,
            "onHandle-remove-item": _ctx.handleRemoveItem,
            "onHandle-show-variant-stock": _ctx.handleShowVariantStock,
            "onHandle-remove-image": _ctx.handleRemoveImage,
            "onAdd-taxes-to-item": _ctx.addTaxesToItem,
            "onHandle-hide-variant-stock": _ctx.handleHideVariantStock,
            "onUpdate-variant-locations": _ctx.updateVariantLocations,
            "onUpdate-variant-location-is-active": _ctx.updateVariantLocationIsActive,
            "onUpdate-location-tracking": _ctx.updateLocationTracking,
            "onFind-or-initialize-variant-location": _ctx.findOrInitializeVariantLocation,
            "onFind-variant-location": _ctx.findVariantLocation,
            "onChange-item": _ctx.changeItem,
            "onImport-variant": _ctx.importVariant,
            "onHandle-cancel": _ctx.handleCancel,
            "onIs-progress": false,
            "onOn-file-changed": _cache[2] || (_cache[2] = $event => (_ctx.setDiscardChangesModal = true)),
            "onRemove-image": _ctx.checkItemChanges
          }, null, 8, ["loadingContent", "is-edit-mode", "selected_location_id", "item", "confirmationService", "selected-locations", "selected-locations-variant", "locations-description", "all-locations", "all-taxes", "selected-taxes", "tax-description", "isSuperadminLoc", "onAdd-variant", "onAdd-locations-to-item", "onHandle-variant-remove", "onHandle-submit", "onHandle-remove-item", "onHandle-show-variant-stock", "onHandle-remove-image", "onAdd-taxes-to-item", "onHandle-hide-variant-stock", "onUpdate-variant-locations", "onUpdate-variant-location-is-active", "onUpdate-location-tracking", "onFind-or-initialize-variant-location", "onFind-variant-location", "onChange-item", "onImport-variant", "onHandle-cancel", "onRemove-image"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDiscardChangesModal)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 2,
          title: "Confirmation",
          message: "Are you sure you want to discard changes ?",
          positiveButton: "OK",
          negativeButton: "Cancel",
          onConfirm: _ctx.navigateAway,
          onCancel: _ctx.confirmationCancel,
          onDismiss: _ctx.confirmationCancel
        }, null, 8, ["onConfirm", "onCancel", "onDismiss"]))
      : _createCommentVNode("", true),
    (_ctx.showConfirm)
      ? (_openBlock(), _createBlock(_component_ConfirmationModal, {
          key: 3,
          title: _ctx.alertTitle,
          message: _ctx.alertMessage,
          positiveButton: "OK",
          onDismiss: _cache[3] || (_cache[3] = $event => (_ctx.showConfirm = false)),
          onConfirm: _ctx.navigateAway
        }, null, 8, ["title", "message", "onConfirm"]))
      : _createCommentVNode("", true)
  ]))
}