
import { Options, Vue } from 'vue-class-component';
import ItemService from '@/services/ItemService';
import TaxService from '@/services/TaxService';

@Options({
  components: {},
  props: ['allTaxes', 'selectedTaxes', 'selectedItem'],
})
export default class TaxSelectModal extends Vue {
  itemService = new ItemService();
  taxService = new TaxService();
  selected: any[] = [];
  allTaxes: any;
  selectedTaxes: any;
  // selectedItem: any
  allSelected = false;
  selectedItem: any = {
    id: '',
    name: '',
    description: '',
    itemType: 1,
    variants: [],
    taxes: [],
    hasImage: true,
  };

  async mounted() {
    this.selected = this.selectedTaxes;
    if (
      this.selected &&
      this.selected.length === this.allTaxes.length &&
      this.allTaxes.length > 1 &&
      this.selected.length > 1
    ) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    if (this.selectedItem && this.selectedItem.taxes.length) {
      this.selected = this.selectedItem.taxes;
    }
  }

  toggleAll(checked: []) {
    this.selected = this.allSelected ? this.allTaxes : [];
  }

  toggleOne(newVal: any[]) {
    if (
      this.selected.length === this.allTaxes.length &&
      this.selected.length > 1 &&
      this.allTaxes.length > 1
    ) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  addTaxesToItem(selected: []) {
    this.$emit('add-taxes-to-item', selected);
  }
}
