
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {},
  props: ['listOfItems', 'variant', 'rVariantFlag'],
})
export default class SearchUpcModal extends Vue {
  selectedVariant: any[] = [];
  selectedVariants: any[] = [];
  selectedItem: any[] = [];
  listOfItems: any = {};
  rVariant: any;
  variant: any;

  async mounted() {
    this.selectedVariant = [];
    this.selectedVariants = [];
    this.selectedItem = [];
  }

  toggleAll(checked: any) {
    if (this.selectedVariants.length > 0) {
      for (let i = 0; i < this.selectedVariants.length; i++) {
        if (this.selectedVariants[i].id === checked.id) {
          delete this.selectedVariants[i];
        } else {
          this.selectedVariants.push(checked);
        }
      }
    } else {
      this.selectedVariants.push(checked);
    }
  }

  uncheckAllCheckboxs() {
    this.selectedVariant = [];
  }

  importUpcsToItem(selectedItem: [], selectedVariants: []) {
    this.rVariant = true;
    this.$emit('import-variant', selectedItem, selectedVariants, this.rVariant);
  }
}
