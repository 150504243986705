export default class LocationDescriptionService {
  public generateLocationDescription(
    locationsToCheck: any[],
    locations: any[],
  ): string {
    if (this.hasAllLocations(locationsToCheck, locations)) {
      return 'All Locations';
    }

    if (this.hasSomeLocations(locationsToCheck, locations)) {
      return `${locationsToCheck.length} Locations`;
    }

    if (this.hasOneLocations(locationsToCheck)) {
      return `${locationsToCheck[0].name}`;
    }

    if (this.hasNoLocations(locationsToCheck)) {
      return 'No Locations';
    }

    return '';
  }

  private hasAllLocations(locationsToCheck: any[], allLocations: any[]) {
    return locationsToCheck.length === allLocations.length;
  }

  private hasSomeLocations(locationsToCheck: any[], allLocations: any[]) {
    return (
      locationsToCheck.length > 1 &&
      locationsToCheck.length < allLocations.length
    );
  }

  private hasOneLocations(locationsToCheck: any[]) {
    return locationsToCheck.length === 1;
  }

  private hasNoLocations(locationsToCheck: any[]) {
    return locationsToCheck.length === 0;
  }
}
