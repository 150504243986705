
import { Options, Vue } from 'vue-class-component';

import AuditTrailTable from '../components/AuditTrailTable.vue';
import ItemService from '../services/ItemService';
import TaxSelectModal from '@/components/TaxSelectModal.vue';
import TaxService from '@/services/TaxService';
import VariantStock from '@/components/VariantStock.vue';
import SearchUpcModal from '@/components/SearchUpcModal.vue';
import LocalStorageService from '../services/LocalStorageService';
import RoleService from '@/services/RoleService';
import SearchService from '@/services/SearchService';
import ConfirmationService from '@/services/ConfirmationService';

// @ts-ignore
import { uniqBy, isEmpty } from 'lodash';
import heic2any from 'heic2any';
import { mapActions, mapGetters } from 'vuex';
import { addError, removeError } from '@/helper/validations';
import { ItemType } from '@/enums/ItemType';

@Options({
  components: {
    AuditTrailTable,
    TaxSelectModal,
    VariantStock,
    SearchUpcModal,
  },
  props: [
    'loadingContent',
    'confirmationService',
    'isEditMode',
    'item',
    'isEditMode',
    'selectedLocations',
    'selectedLocationsVariant',
    'allLocations',
    'allTaxes',
    'locationsDescription',
    'taxDescription',
    'selectedTaxes',
    'taxDescription',
    'isSuperadminLoc',
    'selectedLocationId',
  ],
  providers: [SearchService, LocalStorageService, ConfirmationService],
  data() {
    return {
      bundlePriceSelected: 'unselected',
      inputValue: '',
      isError: false,
      discountError: false,
      customPriceError: false,
      isStrikethrough: false,
      bundleDiscountPrice: 0,
      finalDiscountedPrice: 0,
      errorMessage: '',
      customPrice: 0,
    };
  },
  methods: {
    ...mapActions([
      'getLocationList',
      'setSelectedLocationId',
      'setLocationOptions',
      'setProductsFilter',
      'getProducts',
      'getMarketplaceUPC',
    ]),

    handleCustomPrice() {
      if (this.customPrice <= 0) {
        this.customPriceError = true;
        this.errorMessage = 'You must add a price for the bundle';
      } else {
        this.item.bundleCustomPrice = parseFloat(this.customPrice);
        this.customPriceError = false;
        this.errorMessage = '';
        delete this.item.bundleDiscount;
      }
    },
    handleInputBundleDiscount() {
      const inputValue = parseFloat(this.bundleDiscountPrice);
      const totalPrice = parseInt(
        this.item.price.substring(1).replace(/,/g, ''),
      );
      if (isNaN(inputValue) || inputValue < 1 || inputValue > 100) {
        this.discountError = true;
        this.errorMessage = 'You must chose an amount (1-100)';
        this.isStrikethrough = false;
      } else {
        this.discountError = false;
        this.errorMessage = '';
      }
      if (this.bundleDiscountPrice) {
        this.isStrikethrough = true;
        this.item.bundleDiscount = inputValue;
        delete this.item.bundleCustomPrice;
        this.finalDiscountedPrice =
          totalPrice - (this.bundleDiscountPrice / 100) * totalPrice;
        this.finalDiscountedPrice = this.finalDiscountedPrice.toFixed(2);
      } else {
        this.isStrikethrough = false;
        this.finalDiscountedPrice = 0;
      }
    },
  },

  computed: mapGetters(['allLocationList', 'locationOptions']),
})
export default class ItemDetail extends Vue {
  showDiscardChangesModal = false;
  finalDiscountedPrice = 0;
  discountPriceDisplay: any;
  isStrikethrough = false;
  customPrice = 0;
  imageWasDeleted = false;
  loadingContent = false;
  activateNotification = false;
  typeNotification = 'success';
  titleNotification = '';
  messageNotification = '';
  isDeletePopupBlock = false;
  selectAllFlag = false;
  showModel = false;
  showSearchUpcModal = false;
  saveButtonEnable = false;
  saveConfirmed = false;
  item: any;
  itemRef: any;
  isEditMode: any;
  locationsDescription: any;
  allLocations: any;
  selectedLocations: any;
  selectedLocationsVariant: any;
  taxDescription: any;
  allTaxes: any;
  selectedTaxes: any;
  formErro: any = {};
  itemService = new ItemService();
  taxService = new TaxService();
  searchService = new SearchService();
  localStorageService = new LocalStorageService();
  roleService = new RoleService(new LocalStorageService());
  confirmationService = new ConfirmationService();
  showLocationModel = false;
  showTaxModel = false;
  alertTitle = '';
  alertMessage = '';
  showConfirm = false;
  showDeleteError = false;
  selectedLocationId: any = this.localStorageService.getItem(
    'primary_location_id',
  );
  primary_location_id: any = '';
  bundlePriceOptions: ['Add custom price', 'Add dicount'];
  bundlePriceSelected: any;
  bundleDiscountPrice: 0;
  openedDetailed: any = [];
  breadcrumbs = [
    {
      text: 'Products',
      href: '#/items',
    },
    {
      text: 'Create Product',
      active: true,
    },
  ];
  title = 'Create Product';
  selectedVariantLocations: any = [];
  variantStockArray = [];
  newitem: any = {
    id: '',
    name: '',
    description: '',
    itemType: ItemType.Good,
    manufacturer: '',
    variants: [
      {
        id: '',
        name: '',
        sku: '',
        price: 0,
        variantLocations: [
          {
            location: {
              id: '',
              name: '',
              isActive: true,
              email: '',
              filterByInventoryTracking: false,
            },
            currentStock: 0,
            isInventoryTrackingEnabled: false,
            isActive: false,
            isLowStock: false,
            stockThreshold: 0,
          },
        ],
        isDeleted: false,
        upc: '',
        quantity: 1,
        isActive: false,
      },
    ],
    locations: [],
    hasImage: false,
  };
  bundleCount = 0;
  variant: any = {
    name: '',
    sku: '',
    price: 0,
    upc: '',
    quantity: 1,
    variantLocations: [
      {
        location: {
          id: '',
          name: '',
          isActive: true,
          email: '',
          filterByInventoryTracking: false,
        },
        currentStock: 0,
        isInventoryTrackingEnabled: false,
        isActive: false,
        isLowStock: false,
        stockThreshold: 0,
      },
    ],
    isActive: false,
  };
  initialVariantValues: any[] = [
    {
      name: '',
      sku: '',
      price: 0,
      quantity: 1,
      variantLocations: [
        {
          location: {
            id: '',
            name: '',
            isActive: true,
            email: '',
            filterByInventoryTracking: false,
          },
          currentStock: 0,
          isInventoryTrackingEnabled: false,
          isActive: false,
          isLowStock: false,
          stockThreshold: 0,
        },
      ],
      upc: '',
      isActive: false,
    },
  ];
  selectedVariantSnapshot: any = {
    name: '',
    sku: '',
    price: 0,
    quantity: 1,
    variantLocations: [
      {
        location: {
          id: '',
          name: '',
          isActive: true,
          email: '',
          filterByInventoryTracking: false,
        },
        currentStock: 0,
        isInventoryTrackingEnabled: false,
        isActive: false,
        isLowStock: false,
        stockThreshold: 0,
      },
    ],
    upc: '',
    isActive: false,
  };
  changes: any[] = [];
  currentVariantHistoryId = '';
  showItemAuditTable = false;
  changesQueried = false;
  showAuditTable = false;
  changesVariantQueried = false;
  imageLoaded = false;
  itemImage: File | null = null;
  itemImageURL = '';
  itemImageKey: string | null = null;
  showDeleteButton = true;
  sizeError = false;
  loading = false;
  imageMaxSize = 2000000;
  imageTypes: Array<any> = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/pjpeg',
    'image/heif',
    'image/heic',
  ];
  isProductType = 'Good';
  productTypeName = 'Product';
  isBundle: any = false;
  isSuperadminLocation = false;
  filteredItems: any[] = [];
  items: any[] = [];
  bundles: any[] = [];
  searchTerm: any = '';
  searchTimeout: any;
  listOfItem: any = [];
  listOfItems: any = [];
  removingVariant: any = [];
  rVariantFlag = false;
  pageCount = 1;
  pageFlag = true;
  selectedColumns: any[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'manufacturer',
      sortable: true,
    },
    {
      key: 'variantName',
      sortable: true,
    },
    {
      key: 'variantPrice',
      sortable: true,
    },
  ];
  formdesable = false;
  origionalItems: any = [];
  bundleVariantIds: any = [];
  locations: Location[] = [];
  bundleItemsloading = false;
  selectedBundleLocation: any = this.localStorageService.getItem(
    'primary_location_id',
  );
  disableLocation = false;
  locationOptions: any = [];

  selectAll() {
    this.selectAllFlag = !this.selectAllFlag;

    this.item.bundles = this.selectAllFlag ? this.filteredItems : [];
  }

  async deleteItem(row: any) {
    this.item.bundles.splice(this.item.bundles.indexOf(row), 1);
    const verintIndex = this.bundleVariantIds.indexOf(row.variantId);
    this.bundleVariantIds.splice(
      this.bundleVariantIds.indexOf(row.variantId),
      1,
    );
    this.searchTerm = '';
    if (this.item.itemType === ItemType.Bundle) {
      this.removeBundleVariant(row);
    }
    await this.conductSearch();
  }

  expandRow(row: any) {
    if (this.openedDetailed.includes(row.id)) {
      this.openedDetailed = this.openedDetailed.filter(
        (openedRow: string) => openedRow !== row.id,
      );
    } else {
      this.openedDetailed.push(row.id);
    }
  }

  disabledExists = (row: any) => {
    if (this) {
      for (let i = 0; i < this.item.bundles; i++) {
        if (row.variantId === this.item.bundles[i].varinatId) {
          return true;
        }
      }
    }
    return false;
  };

  async mounted() {
    this.items = [];
    this.filteredItems = [];
    this.removingVariant = [];
    this.isBundle = !!(this.$route.query && this.$route.query.bundle);

    this.loading = true;

    if (this.isEditMode) {
      this.title = this.title.replace('Create Product', 'Edit Product');
      this.breadcrumbs[1].text = 'Edit Product';
      if (this.item.itemType === ItemType.Bundle) {
        this.selectedLocationId = this.$route.query.locationId.toString();
        if (this.item.bundleDiscount) {
          this.isStrikethrough = true;
          this.bundlePriceSelected = 'add_discount';
          this.bundleDiscountPrice = this.item.bundleDiscount;
          this.finalDiscountedPrice =
            this.item.totalPrice -
            (this.item.bundleDiscount / 100) * this.item.totalPrice.toFixed(2);
        }
        if (this.item.bundleCustomPrice) {
          this.customPrice = parseFloat(this.item.bundleCustomPrice);
          this.bundlePriceSelected = 'custom_price';
        }
      } else {
        this.selectedLocationId = this.$route.query.locationId.toString();
      }
      this.disableLocation = true;
    }

    if (!this.selectedLocationId) {
      this.selectedLocationId = this.localStorageService.getItem(
        'primary_location_id',
      );
    }

    this.getVariantAmount();
    this.loading = false;

    if (this.isBundle) {
      this.item.itemType = ItemType.Bundle;
    }
    this.item.price = 0;
    if (this.item.bundles) {
      this.item.bundles.forEach((element: any) => {
        this.bundleVariantIds.push(element.variantId);
        this.item.price += parseFloat(element.variantPrice.toString());
      });
    }
    this.item.price = this.getPriceVal(this.item.price);

    this.changeProductType(this.item.itemType);
    //-----store implementation----//
    this.$store.dispatch('getLocationList').then(() => {
      this.locations = this.$store.getters.allLocationList;
      this.setLocationOptions();
    });

    this.selectedBundleLocation = this.localStorageService.getItem(
      'primary_location_id',
    );
    if (this.isBundle && this.selectedLocations && this.isEditMode) {
      this.selectedBundleLocation = this.$route.query.locationId.toString();
      this.conductSearch();
      this.disableLocation = true;
    }
    const locationOfSuperadmin: any =
      this.localStorageService.getItem('locations');
    if (this.roleService.isSuperadmin) {
      this.isSuperadminLocation = this.item.locations.some(
        (location: any) => location.id === locationOfSuperadmin.slice(2, -2),
      );
    } else {
      this.isSuperadminLocation = false;
    }
    if (this.roleService.isSuperadmin && !this.isEditMode) {
      this.formdesable = false;
    } else if (
      this.roleService.isSupportOrStaff || this.roleService.isSuperadmin
        ? !this.isSuperadminLocation
        : false
    ) {
      this.formdesable =
        this.roleService.isSupportOrStaff || this.roleService.isSuperadmin
          ? !this.isSuperadminLocation
          : false;
    }
    if (
      this.isBundle &&
      locationOfSuperadmin != null &&
      locationOfSuperadmin != undefined
    ) {
      const _locationOfUsers = JSON.parse(locationOfSuperadmin);
      if (_locationOfUsers.length === 1) {
        this.selectedBundleLocation = _locationOfUsers[0];
        await this.getItemsForLocation(_locationOfUsers[0]);
      }
    }
    if (this.isBundle) await this.updateLocation(this.selectedBundleLocation);
    this.addLocationsToItem();
    this.itemRef = { ...this.item };
  }

  showNotifications(data: any) {
    this.activateNotification = data.activate;
    this.typeNotification = data.type;
    this.titleNotification = data.title;
    this.messageNotification = data.message;
  }

  private setLocationOptions() {
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    if (
      this.isBundle &&
      locationOfSuperadmin &&
      this.roleService.isSuperadmin &&
      !this.isEditMode
    ) {
      this.locationOptions = this.locationOptions.concat(
        this.allLocations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
    } else {
      this.locationOptions = this.locationOptions.concat(
        this.locations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
    }
  }

  searchByText() {
    this.filteredItems = [];
    this.origionalItems = [];
    this.pageFlag = false;
    this.pageCount = 1;
    this.getItemsForLocation(this.selectedBundleLocation);
  }

  async searchByTextDebouncing() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    // Search starts when user not pressed any key for 1000ms
    this.searchTimeout = setTimeout(() => {
      this.searchByText();
    }, 1000);
  }

  scrollFunction(obj: any) {
    const scrollHeight = document.getElementById('scrollDiv')?.scrollHeight;
    const clientHeight = document.getElementById('scrollDiv')?.clientHeight;
    const scrollTop = document.getElementById('scrollDiv')?.scrollTop;
    let scrollHeightNumber = 0;
    let scrollTopNumber = 0;
    if (scrollHeight && clientHeight && scrollTop) {
      scrollHeightNumber = scrollHeight - clientHeight;
      scrollTopNumber = scrollTop;
    }
    const topNeve = document.getElementById('scrollDiv');
    if (this.pageFlag && topNeve) {
      if (
        scrollTopNumber > scrollHeightNumber - 10 &&
        scrollHeightNumber != 0
      ) {
        this.pageFlag = false;
        this.pageCount++;
        this.getItemsForLocation(this.selectedBundleLocation);
      }
    }
  }

  async updateLocation(locationId: string) {
    if (this.isBundle) {
      if (!this.isEditMode) {
        this.origionalItems = [];
        this.bundleVariantIds = [];
        this.item.bundles = [];
        this.filteredItems = [];
        this.searchTerm = '';
        this.item.price = '$0';
      }
    }
    await this.getItemsForLocation(locationId);
  }

  getUniqueListBy(arr: any, key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  async getItemsForLocation(locationId: string) {
    this.bundleItemsloading = true;
    if (this.isBundle) {
      // implement store
      //-----store implementation----//
      this.selectedVariantLocations = await this.locations.filter(
        (location: any) => {
          return location.id === locationId;
        },
      );
      const filter = this.$store.getters.productsFilter;
      filter.page = this.pageCount;
      filter.pageSize = 200;
      filter.locationIds = locationId;
      filter.itemType = ItemType.Good;
      filter.search = this.searchTerm;
      filter.searchField = '';
      filter.orderBy = 'ASC';
      filter.lowStock = 0;
      filter.outofStock = 0;
      filter.sortBy = '';
      filter.sortType = '';
      await this.$store.dispatch('setProductsFilter', filter).then(() => {
        this.$store.dispatch('getProducts').then(() => {
          const filter = this.$store.getters.productsFilter;
          this.$emit('add-locations-to-item', this.selectedVariantLocations);
          this.bundleItemsloading = false;
          this.items = this.$store.getters.allProducts;
          for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (
              item.itemType === ItemType.Good &&
              Array.isArray(item.variants)
            ) {
              for (let j = 0; j < item.variants.length; j++) {
                const variant = item.variants[j];
                const new_item: any = {};
                new_item.name = item.name;
                new_item.manufacturer = item.manufacturer;
                new_item.itemId = item.id;
                new_item.itemType = item.itemType;
                new_item.variantId = variant.id;
                new_item.variantName = variant.name;
                new_item.variantPrice = variant.price;
                new_item.variantSku = variant.sku;
                new_item.variantUpc = variant.upc;
                new_item.bundleSKU = this.item.bundleSKU;
                new_item.bundleItemId = this.item.id;
                this.origionalItems.push(new_item);
              }
            }
          }
          const itemData = this.origionalItems.filter((item: any) => {
            return !this.bundleVariantIds.includes(item.variantId);
          });
          if (Array.isArray(this.item.bundles) && this.item.bundles.length) {
            this.filteredItems = [...this.filteredItems, ...itemData];
            this.filteredItems = this.getUniqueListBy(
              this.filteredItems,
              'variantId',
            );
          } else {
            this.filteredItems = [
              ...this.filteredItems,
              ...this.origionalItems,
            ];
            this.filteredItems = this.getUniqueListBy(
              this.filteredItems,
              'variantId',
            );
          }
          if (this.filteredItems.length < filter.totalCount) {
            this.pageFlag = true;
          }
        });
      });
    }
  }

  getSkuValue(data: any) {
    const variantCount = data.variants.length;

    if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].sku;
    } else {
      return 'Variable';
    }
  }

  getPriceValue(data: any) {
    const variantCount = data.variants.length;

    if (variantCount === 0) {
      return ' ';
    } else if (variantCount === 1) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return data.variants[0].price === null ||
        Number.isNaN(data.variants[0].price)
        ? '-'
        : formatter.format(data.variants[0].price);
    } else {
      return 'Variable';
    }
  }

  removeBundleVariant(row: any) {
    this.showDiscardChangesModal = true;
    this.bundleVariantIds = this.bundleVariantIds.filter((variantId: any) => {
      if (row === variantId) {
        return false;
      } else {
        return true;
      }
    });
    this.bundleCount--;
    this.item.price = 0;
    this.item.bundles = this.item.bundles.filter((bundle: any) => {
      if (row === bundle.variantId) {
        return false;
      } else {
        this.item.price += parseFloat(bundle.variantPrice);
        return true;
      }
    });
    this.item.price = this.getPriceVal(this.item.price);
    this.filteredItems = this.origionalItems.filter(() => {
      return !this.bundleVariantIds.includes(row);
    });
    return false;
  }

  getItemTypeValue(data: any) {
    if (data.itemType === ItemType.Good) {
      return 'Goods';
    } else if (data.itemType === ItemType.Service) {
      return 'Services';
    }
  }

  getTotalItemStock(item: any): number | string {
    const variantStocks: any[] = item.variants.map((variant: any) => {
      return this.getTotalVariantStock(variant, item);
    });
    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (integerVariantStocks.length > 0) {
      return integerVariantStocks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return '-';
  }

  getTotalVariantStock(variant: any, item: any): number | string {
    const locationIds = item.locations.map((location: any) => location.id);
    const trackedVariantLocations: any[] = variant.variantLocations.filter(
      (variantLocation: any) => {
        if (variantLocation.location) {
          return (
            variantLocation.isActive &&
            variantLocation.isInventoryTrackingEnabled &&
            variantLocation.location &&
            locationIds.includes(variantLocation.location.id)
          );
        } else {
          return false;
        }
      },
    );
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation) => {
          return variantLocation.currentStock;
        },
      );
      return stocksByLocation.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return '-';
  }

  getLowItemStock(item: any): number | string {
    const variantCount = item.variants.length;
    const variantStocks: any[] = item.variants.map((variant: any) => {
      return this.getVariantLowStock(variant, item);
    });
    const integerVariantStocks: number[] = variantStocks.filter((stock) => {
      return Number.isInteger(stock);
    });
    if (variantCount === 1) {
      if (integerVariantStocks.length > 0) {
        return integerVariantStocks.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      } else return '-';
    } else {
      return '';
    }
  }

  getVariantLowStock(variant: any, item: any) {
    const locationIds = item.locations.map((location: any) => location.id);
    const trackedVariantLocations: any[] = variant.variantLocations.filter(
      (variantLocation: any) => {
        return (
          variantLocation.isActive &&
          variantLocation.isLowStock &&
          variantLocation.location &&
          locationIds.includes(variantLocation.location.id)
        );
      },
    );
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation) => {
          return variantLocation.stockThreshold;
        },
      );
      return stocksByLocation.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return '-';
  }

  getUpcValue(data: any) {
    const variantCount = data.variants.length;

    if (variantCount === 0) {
      return '';
    } else if (variantCount === 1) {
      return data.variants[0].upc === null ? '' : `${data.variants[0].upc}`;
    }
  }

  getPriceVal(value: any) {
    if (value === null) {
      return 0.0;
    } else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return value === null || Number.isNaN(value)
        ? '-'
        : formatter.format(value);
    }
  }

  loadImage() {
    this.imageLoaded = false;
    setTimeout(() => {
      this.imageLoaded = true;
    }, 1000);
  }

  onFileChanged(event: any) {
    const input: any = this.$refs.formFile;
    const file = input.files;

    if (file) {
      var filename = file[0].name.toString().toLowerCase();
    }

    if (
      file &&
      (filename.includes('.heic') || filename.includes('.heif')) &&
      file[0].size < this.imageMaxSize
    ) {
      this.itemImageURL = URL.createObjectURL(file[0]);
      fetch(this.itemImageURL)
        .then((res: any) => res.blob())
        .then((blob: any) =>
          heic2any({
            blob,
            quality: 0.1,
          }),
        )
        .then((conversionResult: any) => {
          this.itemImage = new File([conversionResult], 'example.jpeg', {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          this.itemImageURL = URL.createObjectURL(this.itemImage);
          this.item.hasImage = true;
        })
        .catch((e) => {
          console.error(e);
        });
      this.sizeError = false;
    } else if (
      (file[0] && file[0].size > this.imageMaxSize) ||
      (file[0] && !this.imageTypes.includes(file[0].type))
    ) {
      this.addError(
        'image',
        'Image file should be of type jpeg, png, gif, heic or heif and be 2MB or smaller in size',
        'format',
      );
      this.sizeError = true;
      this.removeImage();
    } else if (
      file[0] &&
      file[0].size < this.imageMaxSize &&
      this.imageTypes.includes(file[0].type)
    ) {
      this.itemImageURL = URL.createObjectURL(file[0]);
      this.removeError('image', 'format');
      this.sizeError = false;
      this.itemImage = file[0];
    }
    this.$emit('on-file-changed');
  }

  removeImage() {
    if (this.$refs.formFile) {
      (this.$refs.formFile as any).value = '';
    }
    this.sizeError = false;
    this.itemImage = null;
    this.imageLoaded = false;
    this.item.hasImage = false;
    if (this.isEditMode) {
      this.item.imageSrc = undefined;
      this.imageWasDeleted = true;
    }
    this.$emit('remove-image');
  }
  selectItem(row: any) {
    this.showDiscardChangesModal = true;
    if (
      Array.isArray(this.item.bundles) &&
      this.item.bundles.indexOf(row) < 0
    ) {
      this.item.bundles.push(row);
    } else {
      this.item.bundles.splice(this.item.bundles.indexOf(row), 1);
    }
    this.item.price = 0;
    this.item.bundles.forEach((element: any) => {
      this.bundleVariantIds.push(element.variantId);
      element.bundleSKU = this.item.bunldeSKU;
      this.item.price += parseFloat(element.variantPrice.toString());
    });
    const totalPrice = this.item.price;
    this.finalDiscountedPrice =
      totalPrice - (this.bundleDiscountPrice / 100) * totalPrice;
    this.finalDiscountedPrice = parseFloat(
      this.finalDiscountedPrice.toFixed(2),
    );
    this.item.price = this.getPriceVal(this.item.price);

    this.bundleCount++;
    this.$forceUpdate();
  }

  calculateTotalStock(variant: any) {
    const locationIds = this.selectedLocations.map(
      (location: any) => location.id,
    );
    const trackedVariantLocations: any[] = variant.variantLocations.filter(
      (variantLocation: any) => {
        return (
          variantLocation.isActive &&
          variantLocation.isInventoryTrackingEnabled &&
          variantLocation.location &&
          locationIds.includes(variantLocation.location.id)
        );
      },
    );
    if (trackedVariantLocations.length > 0) {
      const stocksByLocation = trackedVariantLocations.map(
        (variantLocation) => {
          return variantLocation.currentStock;
        },
      );
      return stocksByLocation.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
    } else return 'Not Tracked';
  }

  async conductSearch() {
    this.filteredItems = [];
    this.origionalItems = [];
    this.selectedVariantLocations = await this.locations.filter(
      (location: any) => {
        return location.id === this.selectedBundleLocation;
      },
    );
    if (this.searchTerm) {
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        200,
        this.searchTerm,
        this.selectedBundleLocation,
      );
      this.items = itemResp.data.items;
      this.$emit('add-locations-to-item', this.selectedVariantLocations);
      if (Array.isArray(this.items)) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.itemType === ItemType.Good && Array.isArray(item.variants)) {
            for (let j = 0; j < item.variants.length; j++) {
              const variant = item.variants[j];
              const new_item: any = {};
              new_item.name = item.name;
              new_item.manufacturer = item.manufacturer;
              new_item.itemId = item.id;
              new_item.itemType = item.itemType;
              new_item.variantId = variant.id;
              new_item.variantName = variant.name;
              new_item.variantPrice = variant.price;
              new_item.variantSku = variant.sku;
              new_item.variantUpc = variant.upc;
              new_item.bundleSKU = this.item.bundleSKU;
              new_item.bundleItemId = this.item.id;
              this.origionalItems.push(new_item);
            }
          }
        }
      }
      this.filteredItems = this.origionalItems.filter((item: any) => {
        return !this.bundleVariantIds.includes(item.variantId);
      });
      this.filteredItems = this.getUniqueListBy(
        this.filteredItems,
        'variantId',
      );
    } else {
      const itemResp = await this.itemService.getAllItemsFor(
        1,
        200,
        '',
        this.selectedBundleLocation,
      );
      this.items = itemResp.data.items;
      this.$emit('add-locations-to-item', this.selectedVariantLocations);
      if (Array.isArray(this.items)) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.itemType === ItemType.Good && Array.isArray(item.variants)) {
            for (let j = 0; j < item.variants.length; j++) {
              const variant = item.variants[j];
              const new_item: any = {};
              new_item.name = item.name;
              new_item.manufacturer = item.manufacturer;
              new_item.itemId = item.id;
              new_item.itemType = item.itemType;
              new_item.variantId = variant.id;
              new_item.variantName = variant.name;
              new_item.variantPrice = variant.price;
              new_item.variantSku = variant.sku;
              new_item.variantUpc = variant.upc;
              new_item.bundleSKU = this.item.bundleSKU;
              new_item.bundleItemId = this.item.id;
              this.origionalItems.push(new_item);
            }
          }
        }
      }

      this.filteredItems = this.origionalItems.filter((item: any) => {
        return !this.bundleVariantIds.includes(item.variantId);
      });
      this.filteredItems = this.getUniqueListBy(
        this.filteredItems,
        'variantId',
      );
    }
  }

  async toggleShowItemAuditTable() {
    if (!this.showItemAuditTable)
      this.changes = await this.itemService.getAuditTrailForItem(this.item.id);
    this.showItemAuditTable = !this.showItemAuditTable;
    this.showAuditTable = false;
  }

  async toggleShowAuditTable(variantId) {
    if (this.currentVariantHistoryId !== variantId) {
      this.currentVariantHistoryId = variantId;
      this.changes = await this.itemService.getStockAuditTrailForVariant(
        this.item.id,
        variantId,
      );
      this.changesVariantQueried = true;
    }
    this.showAuditTable = !this.showAuditTable;
    this.showItemAuditTable = false;
  }

  getAuditButtonVerb(varId) {
    if (
      (this.showAuditTable && varId == this.currentVariantHistoryId) ||
      (this.showItemAuditTable && varId == this.item.id)
    )
      return 'Hide';
    else return 'Show';
  }

  private addVariant(): void {
    this.showDeleteError = false;
    this.$emit('add-variant');
    this.getVariantAmount();
  }

  addLocationsToItem(): void {
    this.item.locations = this.allLocations.filter(
      (l: any) => this.selectedLocationId === l.id,
    );
    const locationObj = this.allLocations.filter(
      (l: any) => this.selectedLocationId === l.id,
    );
    this.$emit('add-locations-to-item', locationObj);
    this.showLocationModel = false;
  }

  private addTaxesToItem(selectedTaxes: any[]): void {
    this.$emit('add-taxes-to-item', selectedTaxes);
    this.showTaxModel = false;
  }

  /**
   *  Validate if UPC has between 12 and 13 digits
   */
  async checkUpcValidation(): Promise<boolean> {
    this.loading = true;
    if (this.item.itemType === ItemType.Good) {
      this.item.variants = this.item.variants.map((value: any) => {
        const _upc = value.upc || ''; //there are some records in the DB that have null instead of empty string.
        const upcLength = _upc.length;
        const message =
          (upcLength >= 1 && upcLength < 12) || upcLength > 13
            ? 'UPC must have between 12 and 13 digits'
            : '';
        return {
          ...value,
          messageValidations: {
            upc: message,
          },
        };
      });

      for (let index = 0; index < this.item.variants.length; index++) {
        const _upc = this.item.variants[index].upc || '';
        const upcLength = _upc.length;
        const variantId = this.item.variants[index].id ?? null;

        if (upcLength >= 12 && upcLength <= 13) {
          const upcAlreadyExist = await this.itemService.validateVariantUpc(
            1,
            1,
            this.item.variants[index].upc,
            this.selectedLocationId,
            variantId,
          );
          if (upcAlreadyExist.data.items.length > 0) {
            this.item.variants[index].messageValidations.upc =
              'UPC already exists. Please update the UPC number to save your changes.';
          }
        }
      }

      this.loading = false;
      return (
        this.item.variants.filter(
          (value: any) => value.messageValidations.upc !== '',
        ).length <= 0
      );
    }

    return true;
  }

  async checkValidation() {
    let validFlag = true;
    if (!this.isValidName) {
      validFlag = false;
    } else if (
      (this.item.itemType === ItemType.Good ||
        this.item.itemType === ItemType.Service) &&
      this.selectedLocationId === null
    ) {
      validFlag = false;
      this.formErro.location = 'Please select a location';
    } else if (
      this.checkVariantDuplicate(this.item.variants) ||
      this.checkVariantUpcDuplicate(this.item.variants)
    ) {
      validFlag = false;
    } else if (!(await this.checkUpcValidation())) {
      validFlag = false;
    }
    return validFlag;
  }

  get isValidName() {
    if (isEmpty(this.item.name.trim())) {
      this.addError('name', 'Product Name is required', 'required');
      return false;
    } else {
      this.removeError('name', 'required');
      return true;
    }
  }

  checkVariantDuplicate(variants: any) {
    const variantNames: any = [];
    variants.map((variant: any) => {
      variantNames.push(variant.isDuplicate);
      return variant;
    });
    return variantNames.includes(true);
  }

  checkVariantUpcDuplicate(variants: any) {
    const variantUpc: any = [];
    variants.map((variant: any) => {
      variantUpc.push(
        variant.isUpcDuplicate && variant.upc !== '' && variant.upc !== null,
      );
      return variant;
    });
    return variantUpc.includes(true);
  }

  cancelSave() {
    this.showConfirm = false;
  }
  negativeButton() {
    this.showConfirm = false;
  }
  saveConfirmation() {
    this.saveConfirmed = true;
    this.showConfirm = false;
    this.handleSubmit();
  }
  async handleSubmit() {
    this.saveButtonEnable = false;
    const itemVariant = this.item.variants[0];
    if (itemVariant) {
      const wholesalePrice = parseFloat(itemVariant.wholesalePrice);
      const retailPrice = parseFloat(itemVariant.price);
      if (wholesalePrice > retailPrice && !this.saveConfirmed) {
        this.saveButtonEnable = true;
        this.alertTitle = 'Warning';
        this.alertMessage =
          'The Retail Price entered is lower than the Wholesale Price. Do you wish to continue?';
        this.showConfirm = true;
        return;
      }
    }
    if (await this.checkValidation()) {
      if (
        this.item.itemType === ItemType.Bundle &&
        this.selectedBundleLocation === ''
      ) {
        this.$emit('is-progress', false);
        this.alertTitle = 'alert';
        this.alertMessage = 'Please select a location for bundle';
        this.showConfirm = true;
      } else if (
        this.item.itemType === ItemType.Bundle &&
        this.item.bundles.length < 1
      ) {
        this.$emit('is-progress', false);
        this.alertTitle = 'alert';
        this.alertMessage = 'Please select atleast 1 variant for bundle';
        this.showConfirm = true;
      } else {
        this.loading = true;
        this.$emit('is-progress', true);

        if (this.isEditMode && this.item.itemType === ItemType.Service) {
          this.item.taxes = this.selectedTaxes;
        }

        if (
          this.imageWasDeleted &&
          this.item.imgKey !== undefined &&
          this.item.imgKey !== null
        ) {
          this.$emit('handle-remove-image', this.item);
          this.$nextTick(() => {
            if (this.itemImage === null) {
              this.$emit('handle-submit', this.itemImageKey);
            } else {
              this.$emit('handle-submit', this.itemImage);
            }
          });
        } else {
          if (this.itemImage === null) {
            this.$emit('handle-submit', this.itemImageKey);
          } else {
            this.$emit('handle-submit', this.itemImage);
          }
        }
        this.loading = false;
      }
    } else {
      this.alertTitle = 'alert';
      this.alertMessage = 'Please check all data is valid';
      this.showConfirm = true;
      this.saveButtonEnable = false;
      this.saveConfirmed = false;
    }
  }

  private handleRemoveItem() {
    this.isDeletePopupBlock = true;
  }

  private confirmationDeleteOk() {
    this.loading = true;
    this.$emit('handle-remove-item');
    this.isDeletePopupBlock = false;
  }

  private confirmationDeleteCancel() {
    this.isDeletePopupBlock = false;
  }

  private handleVariantRemove(variant: any) {
    // Check if last varient is getting deleted
    if (this.item.variants.length <= 1) {
      this.showDeleteError = true;
      //Displays validation for 5000ms
      setTimeout(() => {
        this.showDeleteError = false;
      }, 5000);
      return false;
    }

    this.variant = variant;
    this.variant.isActive = false;
    this.$emit('handle-variant-remove', this.variant);
    this.getVariantAmount();
  }

  private handleShowVariantStock(variant: any) {
    this.$emit('handle-show-variant-stock');
    this.variant = variant;
    this.variant.isActive = true;
    let variantIndex = this.newitem.variants.indexOf(this.variant);
    if (variantIndex < 0) {
      variantIndex = 0;
    }
    this.selectedVariantSnapshot = this.initialVariantValues[variantIndex];
    this.$forceUpdate();
  }

  private handleHideVariantStock(variant: any) {
    this.variant = variant;
    this.variant.isActive = false;
    this.$emit('handle-hide-variant-stock', this.variant);
    this.$forceUpdate();
  }

  updateVariantLocations(
    variant: any,
    variantLocations: any[],
    selectedStockActions: any,
    deltaStockValues: any,
    variantLocationSnapshots: any[],
    stockActions: any,
  ): void {
    this.variant = variant;
    this.variant.isActive = false;
    this.$emit(
      'update-variant-locations',
      this.variant,
      variantLocations,
      selectedStockActions,
      deltaStockValues,
      variantLocationSnapshots,
      stockActions,
    );
  }

  updateVariantLocationIsActive(
    event: boolean,
    variantLocations: any[],
    location: any,
  ) {
    this.$emit(
      'update-variant-location-is-active',
      event,
      variantLocations,
      location,
    );
  }

  updateLocationTracking(
    event: boolean,
    variantLocations: any[],
    location: any,
  ) {
    this.$emit('update-location-tracking', event, variantLocations, location);
  }

  findOrInitializeVariantLocation(variant: any, location: any): any {
    let variantLocation = variant.variantLocations.find(
      (variantLocation: any) => {
        return variantLocation.location.id === location.id;
      },
    );
    if (variantLocation === undefined) {
      variantLocation = {
        location: location,
        isInventoryTrackingEnabled: false,
        currentStock: 0,
        isActive: true,
        untouched: true,
        isLowStock: false,
        stockThreshold: 0,
      };
      variant.variantLocations.push(variantLocation);
    }
    return variantLocation;
  }

  findVariantLocation(variant: any, location: any): any | undefined {
    const variantLocation = variant.variantLocations.find(
      (variantLocation: any) => {
        return variantLocation.location.id === location.id;
      },
    );
    return variantLocation;
  }

  getVariantAmount() {
    if (this.item.variants.length === 1) {
      this.showDeleteButton = false;
    } else {
      this.showDeleteButton = true;
    }
  }

  closeLocation() {
    this.showLocationModel = false;
  }
  handleHideVariantTax() {
    this.showTaxModel = false;
  }
  priceFormatter($event: any) {
    if (($event.keyCode < 48 || $event.keyCode > 57) && $event.keyCode !== 46) {
      $event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  upcFormatter($event: any) {
    if ($event.keyCode < 48) {
      return false;
    }
    if ($event.keyCode > 57) {
      $event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  showConfirmationModel() {
    if (this.item.itemType === ItemType.Service) {
      this.showTaxModel = true;
    } else {
      this.showTaxModel = true;
    }
  }

  confirmApplyTaxes() {
    // this.$bvModal.hide('modal-confirm')
    // this.$bvModal.show('tax-select-modal')
  }

  hideModalConfirm() {
    this.showModel = false;
  }

  private handleCancel() {
    const value = this.showDiscardChangesModal;
    this.$emit('handle-cancel', value);
  }

  closeUPCModel() {
    this.showSearchUpcModal = false;
  }
  importVariant(
    selectedItem: any,
    selectedVariant: any,
    rVariantFlag: any,
  ): void {
    for (let i = 0; i < selectedVariant.length; i++) {
      this.$emit('import-variant', selectedVariant[i]);
    }

    this.getVariantAmount();
    this.rVariantFlag = rVariantFlag;
    if (this.rVariantFlag) {
      this.handleVariantRemove(this.removingVariant);
    }

    if (selectedItem[0]) {
      selectedItem = selectedItem[0];
    }

    this.item.name = selectedItem.name;
    this.item.manufacturer = selectedItem.manufacturer;
    this.item.description = selectedItem.description;
    this.item.hasImage = selectedItem.hasImage;
    this.item.imageSrc = selectedItem.imageSrc;
    this.itemImageKey =
      selectedItem.imageSrc !== undefined
        ? selectedItem.imageSrc.split('.com/')[1]
        : null;
    this.showSearchUpcModal = false;
  }

  changeProductType(type: number) {
    if (type === 3) {
      this.isProductType = 'Bundle';
      this.productTypeName = 'Bundle';
    } else if (type === 2) {
      this.isProductType = 'Service';
      this.productTypeName = '';
    } else {
      this.productTypeName = 'Product';
    }
  }

  showSearchUpcModel(sUPC: any, rVariant: any) {
    this.loading = true;
    this.showSearchUpcModal = true;
    this.removingVariant = rVariant;
    this.rVariantFlag = false;
    const searchUpc = sUPC;
    this.listOfItem = [];
    this.listOfItems = [];
    if (searchUpc || searchUpc !== '') {
      this.listOfItem = [];

      this.$store
        .dispatch('getMarketplaceUPC', {
          searchField: 'upc',
          search: searchUpc,
        })
        .then(() => {
          this.loading = false;
          this.listOfItem = this.$store.getters.marketplaceUPCItems;

          if (this.listOfItem.length) {
            this.listOfItem = uniqBy(this.listOfItem, 'id');
            this.listOfItems = [];
            for (let i = 0; i < this.listOfItem.length; i++) {
              this.listOfItems.push(this.listOfItem[i]);
            }
          } else {
            this.alertTitle = 'alert';
            this.alertMessage = 'No matched upc found in marketplace';
            this.showConfirm = true;
          }
        });
    }
  }
  private IsAlphaNumeric(e: any) {
    this.saveButtonEnable = false;
    const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
    const ret =
      keyCode === 13 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode == 32;
    if (!ret) {
      this.alertTitle = 'alert';
      this.alertMessage =
        'Characters and Special Characters not allowed in UPC';
      this.showConfirm = true;
    }
    return ret;
  }

  addError(field: any, message: any, error: any) {
    this.formErro = { ...addError(field, message, error, this.formErro) };
  }

  removeError(field: any, error?: any) {
    this.formErro = { ...removeError(field, this.formErro, error) };
  }
}
