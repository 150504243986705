
import {Options, Vue} from 'vue-class-component';
import ItemService from '../services/ItemService';
import {StockAction} from '@/enums/StockAction';
import {StockActionSelection} from '@/enums/StockActionSelection';
import AuditTrailTable from './AuditTrailTable.vue';
import DatepickerList from '../components/DatepickerLite.vue';
import moment from 'moment';

@Options({
  components: {AuditTrailTable, DatepickerList},
  props: [
    'variant',
    'item',
    'selectedLocations',
    'allLocations',
    'allLocationsVariant',
    'roleService',
    'initialVariantValues',
    'selectedVariantSnapshot',
    'isEditMode',
  ],
  watch: {
    fromdate: {
      handler(newValue) {
        if (newValue !== null && newValue !== '') {
          this.variant.expirationDate = new Date(newValue);
        } else {
          this.variant.expirationDate = null;
        }
      },
    },
  },
})
export default class VariantStockInline extends Vue {
  //Props
  variant: any = {};
  allLocations: any = {};
  allLocationsHolder: any = {};
  item: any = {};
  allLocationsPage: Array<any> = [];
  selectedVariantSnapshot: any = {};

  itemService = new ItemService();
  stockAction = StockAction;
  selected: any[] = [];
  allSelected = true;
  stockNegativeCheck = false;
  changes: any[] = [];
  showAuditTable = false;
  changesQueried = false;
  setDate = null;
  variantLocations: any[] = [];
  displayName = '';
  displayId = '';
  allLocationsVariant: any;
  selectedStockActions: any = {};
  deltaStockValues: any = {};
  stockActionOptions: any = [
    {value: StockActionSelection.RECEIVED, text: 'Stock Received'},
    {value: StockActionSelection.RETURN, text: 'Restock Return'},
    {value: StockActionSelection.LOSS, text: 'Loss'},
    {value: StockActionSelection.THEFT, text: 'Theft'},
    {value: StockActionSelection.DAMAGE, text: 'Damage'},
    {value: StockActionSelection.RECOUNT, text: 'Inventory Re-count'},
  ];
  selectedVariantSnapshotNew: any = {};
  // confirmation modal
  alertTitle = '';
  alertMessage = '';
  showConfirm = false;

  // calendar
  fromdate = null;
  isDisabled = false;
  datepickerSetting = {
    id: 'fromdateId',
    name: 'fromDateName',
    class: 'myDateInput',
    yearMinus: '0',
    locale: {
      weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      todayBtn: 'Today',
      clearBtn: 'Clear',
      closeBtn: 'Close',
    },
    // changeEvent: (value:any) => {
    //   this.fromdate = value;
    // },
    changeEvent: (value: any) => {
      //FromDate
      this.isDisabled = false;
      if (moment(value) < moment(new Date())) {
        this.alertTitle = 'Alert';
        this.alertMessage = 'Date should be greater than the today';
        this.showConfirm = true;
        this.isDisabled = true;
        this.fromdate = null;
        this.setDate = 'null';
        return null;
      }
      this.setDate = value;
      this.fromdate = value;
    },
  };

  mounted() {
    this.selectedVariantSnapshotNew = this.selectedVariantSnapshot;
    this.displayName = this.variant.name;
    this.displayId = this.variant.id;

    if (
        this.variant.expirationDate !== null &&
        this.variant.expirationDate !== ''
    ) {
      this.setDate = this.getDateFormat(new Date(this.variant.expirationDate));
      this.fromdate = new Date(this.variant.expirationDate);
    } else {
      this.fromdate = null;
      this.setDate = '';
    }

    if (this.variant.name === '') {
      this.displayName = 'New Variant';
    }
    this.variantLocations = JSON.parse(
        JSON.stringify(this.variant.variantLocations),
    );
    this.selected = this.variantLocations
        .filter((variantLocation: any) => variantLocation.isActive)
        .map((variantLocation) => variantLocation.location);

    // let seconddata = this.allLocations
    this.allLocationsHolder = this.allLocationsVariant[this.displayId]
        ? this.allLocationsVariant[this.displayId]
        : this.allLocations;
    // this.allLocations = this.allLocationsVariant[this.displayId];
    for (let i = 0; i < this.allLocationsHolder.length; i++) {
      this.selectedStockActions[this.allLocationsHolder[i].id] = '';
      const variantLocation = this.variantLocations.find((variantLocation) => {
        return (
            variantLocation &&
            variantLocation.location &&
            this.allLocationsHolder &&
            this.allLocationsHolder[i] &&
            variantLocation.location.id === this.allLocationsHolder[i].id
        );
      });

      if (!this.displayId) {
        this.allLocationsHolder[i].isInventoryTrackingEnabled = false;
      }
      
      this.allLocationsPage.push({
        ...this.allLocationsHolder[i],
        stockThreshold: variantLocation ? variantLocation.stockThreshold : '',
        currentStock: variantLocation ? variantLocation.currentStock : '',
      });
    }

    if (this.selected.length === this.allLocationsHolder.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  checkNegativeValue(selectedOption: any, stockValue: any) {
    if (stockValue < 0 && selectedOption != 'Recount') {
      this.stockNegativeCheck = true;
    } else {
      this.stockNegativeCheck = false;
    }
  }

  stockActionChange() {
    this.stockNegativeCheck = false;
    this.deltaStockValues = {};
  }

  toggleAll(checked: []) {
    this.selected = checked ? this.allLocationsHolder.slice() : [];
  }

  // @Watch('selected')
  toggleOne(newVal: any[]) {
    this.allSelected = newVal.length === this.allLocationsHolder.length;
  }

  handleHideVariantStock(variant: any) {
    this.$emit('handle-hide-variant-stock', variant);
  }

  getAuditButtonVerb() {
    if (this.showAuditTable) return 'Hide';
    else return 'Show';
  }

  async toggleShowAuditTable() {
    if (!this.changesQueried) {
      this.changes = await this.itemService.getStockAuditTrailForVariant(
          this.item.id,
          this.variant.id,
      );
      this.changesQueried = true;
    }
    this.showAuditTable = !this.showAuditTable;
  }

  addLocationsToVariant(variant: any, selected: any[]) {
    this.$emit('add-locations-to-variant', variant, selected);
    this.handleHideVariantStock(variant);
  }

  updateVariantLocationStatus(event: boolean, location: any): void {

    this.$emit(
        'update-variant-location-is-active',
        event,
        this.variantLocations,
        location,
    );
  }

  updateLocationTracking(event: boolean, location: any) {
    this.$emit(
        'update-location-tracking',
        event,
        this.variantLocations,
        location,
    );
  }

  updateVariantLocations(variant: any) {
    for (let variantLocation of this.variantLocations) {
      if (!variantLocation) {
        continue;
      }
      const items = this.allLocationsPage.find((item: any) => item.id === variantLocation.location.id);
      if (!items) {
        variantLocation.stockThreshold = ''
        variantLocation.currentStock = ''
        variantLocation.isInventoryTrackingEnabled = '';
        variantLocation.isLowStock = ''
      } else {
        variantLocation.stockThreshold = Number(items.stockThreshold)
        variantLocation.currentStock = items.currentStock
        variantLocation.isInventoryTrackingEnabled = items.isInventoryTrackingEnabled
        variantLocation.isLowStock = items.isLowStock
      }

    }
    this.$emit(
        'update-variant-locations',
        variant,
        this.variantLocations,
        this.mapStockSelectionsToActions(),
        this.deltaStockValues,
        this.variant.variantLocations,
        this.selectedStockActions,
    );
    this.handleHideVariantStock(variant);
  }

  findOrInitializeVariantLocation(location: any): any {
    let variantLocation = this.variantLocations.find((variantLocation) => {
      return (
          variantLocation &&
          variantLocation.location &&
          location &&
          variantLocation.location.id === location.id
      );
    });
    if (variantLocation === undefined) {
      variantLocation = {
        location: location,
        isInventoryTrackingEnabled: false,
        currentStock: 0,
        isActive: true,
        untouched: true,
        isLowStock: false,
        stockThreshold: 0,
      };
      this.variantLocations.push(variantLocation);
    }
    return variantLocation;
  }

  findVariantLocation(location: any): any | undefined {
    return this.variantLocations.find((variantLocation) => variantLocation?.location?.id === location?.id);
  }

  setDeltasIfRecount(event: string, location: any) {
    if (event === StockActionSelection.RECOUNT) {
      this.deltaStockValues[location.id] =
          this.findOrInitializeVariantLocation(location).currentStock;
    } else {
      this.deltaStockValues[location.id] = 0;
    }
  }

  mapStockSelectionsToActions() {
    const actions: any = {};
    Object.keys(this.selectedStockActions).forEach((key) => {
      switch (this.selectedStockActions[key]) {
        case StockActionSelection.RECEIVED:
          actions[key] = StockAction.ADD;
          break;
        case StockActionSelection.RETURN:
          actions[key] = StockAction.ADD;
          break;
        case StockActionSelection.LOSS:
          actions[key] = StockAction.REMOVE;
          break;
        case StockActionSelection.THEFT:
          actions[key] = StockAction.REMOVE;
          break;
        case StockActionSelection.DAMAGE:
          actions[key] = StockAction.REMOVE;
          break;
        case StockActionSelection.RECOUNT:
          actions[key] = StockAction.RECOUNT;
          break;
        default:
          break;
      }
    });
    return actions;
  }

  stockCalculation(location: any) {
    const locId = this.FindLocationId(location);

    const actualLocationValues = this.findOrInitializeVariantLocation(location);
    if (
        this.trackingIsEnabledAndStockActionSelected(
            actualLocationValues,
            StockAction.ADD,
        )
    ) {
      return `
      ${this.variant.variantLocations[locId].currentStock}+
      ${this.deltaStockValues[location.id] || 0} =
      ${
          this.variant.variantLocations[locId].currentStock +
          (parseInt(this.deltaStockValues[location.id]) || 0)
      }`;
    } else if (
        this.trackingIsEnabledAndStockActionSelected(
            actualLocationValues,
            StockAction.REMOVE,
        )
    ) {
      return `
      ${this.variant.variantLocations[locId].currentStock} -
      ${this.deltaStockValues[location.id] || 0} =
      ${
          this.variant.variantLocations[locId].currentStock -
          (parseInt(this.deltaStockValues[location.id]) || 0)
      }`;
    } else if (
        this.trackingIsEnabledAndStockActionSelected(
            actualLocationValues,
            StockAction.RECOUNT,
        )
    ) {
      return `Was: ${this.variant.variantLocations[locId].currentStock}`;
    }
  }

  private trackingIsEnabledAndStockActionSelected(
      variantLocation: any,
      selectedStockAction: string,
  ) {
    return (
        variantLocation &&
        variantLocation.location &&
        selectedStockAction &&
        variantLocation.isInventoryTrackingEnabled &&
        this.mapStockSelectionsToActions()[variantLocation.location.id] ===
        selectedStockAction
    );
  }

  private isLocationChecked(location: any) {
    return location.isActive;
  }

  closeModal() {
    this.showConfirm = false;
    this.setDate = '';
    this.fromdate = null;
  }

  getDateFormat(currentDate: Date) {
    if (currentDate === null) {
      return '';
    } else {
      return (
          (currentDate.getMonth() > 8
              ? currentDate.getMonth() + 1
              : '0' + (currentDate.getMonth() + 1)) +
          '/' +
          (currentDate.getDate() > 9
              ? currentDate.getDate()
              : '0' + currentDate.getDate()) +
          '/' +
          currentDate.getFullYear()
      );
    }
  }

  FindLocationId(location: any) {
    for (let i = 0; i < this.variant.variantLocations.length; i++) {
      if (
          this.variant &&
          this.variant.variantLocations[i] &&
          this.variant.variantLocations[i].location &&
          location &&
          this.variant.variantLocations[i].location != null &&
          location.id === this.variant.variantLocations[i].location.id
      ) {
        return i;
      }
    }
    return 0;
  }
}
