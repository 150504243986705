import VariantLocation from '@/Interfaces/VariantLocation';
import Location from '@/Interfaces/Location';

export default class VariantLocationService {
  updateVariantLocationStatus(
    event: boolean,
    variantLocations: VariantLocation[],
    location: Location,
  ): void {
    const variantLocation = this.findOrInitializeVariantLocation(
      variantLocations,
      location,
    );
    variantLocation.isActive = !(event === null);
  }

  updateLocationTracking(
    event: boolean,
    variantLocations: VariantLocation[],
    location: Location,
  ): void {
    const variantLocation = this.findOrInitializeVariantLocation(
      variantLocations,
      location,
    );
    variantLocation.isInventoryTrackingEnabled = event;
  }

  updateLocationIsLowStock(
    event: boolean,
    variantLocations: VariantLocation[],
    location: Location,
  ): void {
    const variantLocation = this.findOrInitializeVariantLocation(
      variantLocations,
      location,
    );
    variantLocation.isLowStock = event;
  }

  updateLocationStockThreshold(
    event: number,
    variantLocations: VariantLocation[],
    location: Location,
  ): void {
    const variantLocation = this.findOrInitializeVariantLocation(
      variantLocations,
      location,
    );
    variantLocation.stockThreshold = event;
  }
  updateLocationCurrentStock(
    event: number,
    variantLocations: VariantLocation[],
    location: Location,
  ): void {
    const variantLocation = this.findOrInitializeVariantLocation(
      variantLocations,
      location,
    );
    variantLocation.currentStock = event;
  }

  findOrInitializeVariantLocation(
    variantLocations: VariantLocation[],
    location: Location,
  ): VariantLocation {
    let variantLocation = variantLocations.find((variantLocation) => {
      return variantLocation.location.id === location.id;
    });
    if (variantLocation === undefined) {
      variantLocation = {
        location: location,
        isInventoryTrackingEnabled: false,
        currentStock: 0,
        isActive: true,
        untouched: true,
        isLowStock: false,
        stockThreshold: 0,
      };
      variantLocations.push(variantLocation);
    }
    return variantLocation;
  }

  findVariantLocation(
    variantLocations: VariantLocation[],
    location: Location,
  ): VariantLocation | undefined {
    const variantLocation = variantLocations.find((variantLocation) => {
      return variantLocation.location.id === location.id;
    });
    return variantLocation;
  }
}
